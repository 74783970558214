<template>
  <EditorSizeSlider
    label="Rozmiar tekstu:"
    :min="10"
    :max="60"
    :value.sync="internalValue"
  >
    <div slot="min-icon" class="text small ml-2">A</div>
    <div slot="max-icon" class="text big">A</div>
  </EditorSizeSlider>
</template>

<script>
export default {
  props: {
    value: {},
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  components: {
    EditorSizeSlider: () => import("@/components/editors/EditorSizeSlider"),
  },
};
</script>

<style scoped lang="scss">
.text {
  color: gray;
  &.small {
    font-size: 10px;
  }
  &.big {
    font-size: 18px;
  }
}
</style>